import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { isMobile } from 'react-device-detect';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import { Link, Router } from "routes";
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
// import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import classNames from 'classnames';
// import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BrandTeaserLogo from '/components/brandTeaserLogo';
import Divider from '@material-ui/core/Divider';
import { getCategoryColors } from '/utils/common';

const styles = theme => ({
  expansionPanelSummaryExpanded: {
   margin: '0px!important',
  },
  expansionPanelSummaryRoot: {
    // padding: '0px 16px',
    minHeight: '47px!important',
    height: 47,
    padding: 0
  },
  customExpandIcon: {
    // right: -15
  },
  expansionPanelDetailRoot: {
    // display: 'initial',
    padding: 0,
    display: 'inline-block',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.05)'
  },
  expansionPanelRoot: {
    margin: 0,
    boxSizing: 'border-box',
    boxShadow: 'none',
    '&::before': {
      background: 'transparent'
    }
  },
  dropdownText: {
    paddingLeft: 12,
    color: '#fff',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '23px'
  },
  root: {
    width: '100%',
  },
  logoImgStyle: {
    width: 148,
    objectFit: 'contain',
    [theme.breakpoints.down(340)]: {
      width: 110
    },
    height: 58,
    cursor: 'pointer'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    // marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
 
  headerCenter: {
    textAlign: 'center',
    height: 55
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  flex: {
    flexGrow: 1,
    lineHeight: 0
  },
  toolbarTitle: {
    flex: 1,
    lineHeight: 0
  },
  listText: {
    // color: '#fff',
    // paddingLeft: '16px',
    cursor: 'pointer',
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 'bold'
  },
  drawerImage: {
    width: 197,
    height: 49,
    cursor: 'pointer',
    objectFit: 'contain'
  },
  layout: {
    width: 'auto',
    minHeight: 55,
    [theme.breakpoints.up(1100)]: {
      width: 1140,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  toolbarGutters: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up(1100)]: {
     padding: '0px'
    },
  },
  headerCategory: {
    fontSize: 14, 
    [theme.breakpoints.down(340)]: {
      fontSize: 12, 
    },
  }
});

function DropdownItems(props){
  const { subCatItems, classes, toggleDrawer, catColor } = props;
  return (
    subCatItems && subCatItems.length > 0 && subCatItems.map((cat, index) => {
      return(
      <ListItem
        button
        key={index}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        style={{ background: catColor, marginLeft: 25, padding: 16 }}
      >
         <Link route={cat.type === 'TAG' ? "tagItems" : cat.path} params={cat.type === 'TAG' ? { tag: cat.slug } : {}}>
          <a className="anchor-link">
            <ListItemText
              classes={{
                primary: classes.dropdownText
              }}
              primary={cat.name}
            />
          </a>
        </Link>
      </ListItem>
      )})
  );
}

function DropdownCategory(props){
  const { catName, classes, color, expanded, handleChange, catColor, secondaryColor } = props;
  const primaryColor = expanded === catName ? catColor : '#fff';
  return(
    <ExpansionPanel expanded={expanded === catName}
    onChange={handleChange(catName)} square classes={{root: classes.expansionPanelRoot}} style={{backgroundColor: '#000', color: primaryColor }}>
      <ExpansionPanelSummary classes={{root: classes.expansionPanelSummaryRoot, expanded: classes.expansionPanelSummaryExpanded, expandIcon: classes.customExpandIcon}} expandIcon={<ExpandMoreIcon style={{color: primaryColor}} />}>
        <ListItemText
          disableTypography
          classes={{
            root: classes.listText
          }}
          style={{color: primaryColor, paddingLeft: '25px',}}
          primary={catName}
        />
     
      </ExpansionPanelSummary>
       
      <ExpansionPanelDetails classes={{root: classes.expansionPanelDetailRoot}} style={{ background: secondaryColor}}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
 
  )
}
function CustomTab(props) {
  return (
    <div>
      <Link route={props.type == 'VIDEO' ? "videos" : props.path}>
        <a className="anchor-link">
          <Typography 
            className={props.isActive ? 'headerNavigationActive' : 'headerNavigationColor'}
            onClick={props.onActiveTab}
            style={props.isActive ? {padding: '14px', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer', textTransform: 'capitalize'}: {padding: '14px', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer', textTransform: 'capitalize'}}
            noWrap
          >
            { props.content }
          </Typography>
        </a>
      </Link>
    </div>
  )
};
class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      left: false,
      expanded: false
    };
  }
  // toggleDrawer = (open) => () => {
  //   this.setState({
  //     left: open,
  //   });
  // };

  setActiveTab = (slug) => {
    this.setState(window.scrollTo(0, 0))
      // Router.pushRoute('category', {category: slug})
  }

  render() {
    const { classes, categoryItems, catIndex, isVideoPath, router, asPath, subCatIndex, articleCurrentCat, isArticlePath } = this.props;
    // let articleCategoryItems = categoryItems.filter(item => item.type == 'ARTICLE')
    const videoIndex = categoryItems && categoryItems.findIndex((category) => category.type === 'VIDEO' ? category.type === 'VIDEO': null)
   
    const videoColor = videoIndex > -1 ? categoryItems && categoryItems[videoIndex].color : '#F9461E';
  
    const videoRoutes = asPath.startsWith("/videos") || router.pathname === '/videoDetail';
    const color = videoRoutes ? videoColor : '#F9461E'
    const parentCategory = router.query.category;
    const ascendingSortCategory = categoryItems && categoryItems.sort(function(a, b){return a.order - b.order});

    // const SwipeableDrawerStyle = styled(SwipeableDrawer)`
    // & div:nth-child(2) {
    //   background-color: ${color};
    // }
    // `;
    const categoryItem = catIndex != -1 && categoryItems && categoryItems[catIndex]; 
    const subCategoryItem = subCatIndex != -1 && categoryItem && categoryItem.sub_categories[subCatIndex];
    const catSponsorSlug = categoryItem && categoryItem.sponsor != null ? categoryItem.sponsor.slug : '';
    const subCatSponsorSlug = subCategoryItem && subCategoryItem.sponsor != null ? subCategoryItem.sponsor.slug : '';
    const equalSponsorSlug = catSponsorSlug === subCatSponsorSlug;

    const careersSlug = 'career-education';
    let isCareersSection = categoryItem && categoryItem.slug == careersSlug;
    isCareersSection = subCategoryItem && subCategoryItem.slug == careersSlug ? true : isCareersSection;

    const toggleDrawer = (open) => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      this.setState({left: open});
    };
    const handleChange = panel => (event, expanded) => {
      this.setState({
        expanded: expanded ? panel : false,
      });
    };
    console.log("header.js:categoryItems");
    console.log(categoryItems);
  
    const sideList = (
      <div
        id="toggleDrawer"
        className={classes.list}
        style={{backgroundColor: '#000', height: '100%'}}
        tabIndex={0}
        role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >      
        <List disablePadding>
          <Typography align="center" style={{ padding: '42px 0px'}} >
            <Link route="/">
              <a className="anchor-link" style={{display:'inline-block', minHeight:'76px'}}>
                <img src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/DuwunLogoOrange.svg" className={classes.drawerImage} alt="Duwun logo" />
              </a>
            </Link>  
          </Typography>
          { 
           ascendingSortCategory && ascendingSortCategory.length > 0 && ascendingSortCategory.map((cat, index) =>{
             const allSubCategories = cat && cat.sub_categories;
             const sortSubCategories = allSubCategories && allSubCategories.sort(function(a, b){return a.order - b.order});
             const allCategoriesItems = JSON.parse(JSON.stringify(sortSubCategories));
             allSubCategories.length > 0 && allCategoriesItems.splice(0, 0, {"name": `All ${cat.name}`,
             "slug": cat && cat.slug,
             "color": cat && cat.color,
             "path": cat && cat.path
           });
           const primaryColor = this.state.expanded === cat.name ? cat.color : '#fff';
           let secondaryColor = cat.other_colors && cat.other_colors.secondary_color !== null ? cat.other_colors.secondary_color : getCategoryColors(cat.slug).secondary //{'#0A0A0A'};
             return(
             <React.Fragment key={index}>
               { allSubCategories && allSubCategories.length > 0 ?
               <div>
                <DropdownCategory secondaryColor={secondaryColor} expanded={this.state.expanded} catName={cat.name} classes={classes} color={color} catColor={cat.color} handleChange={handleChange}>
                  <DropdownItems catColor={cat.color} subCatItems={allCategoriesItems} classes={classes} toggleDrawer={toggleDrawer} />
                </DropdownCategory>
               { ascendingSortCategory.length == index + 1 ||
               this.state.expanded !== cat.name && <Divider style={{ background: '#fff', margin: '0px 25px'}} />
               }
                </div>
                :
                <>
                <ListItem button style={{ backgroundColor: '#000' }}>
                    <Link route={cat.path == "/sites/myjobs/careers" ? "/careers" : cat.path}>
                      <a className="anchor-link">
                        <ListItemText
                          classes={{
                            root: classes.listText
                          }}
                          primary={cat.name}
                          disableTypography
                          style={{color: primaryColor, paddingLeft: 9 }}
                      />
                    </a> 
                  </Link>
                </ListItem> 
                {
                ascendingSortCategory.length == index + 1 ||
                
                this.state.expanded !== cat.name  && <Divider style={{ background: '#fff', margin: '0px 25px'}} />}
                  </>
              }
             </React.Fragment>
            )})
          }
        </List>
      </div>
    );

    const commonAppBar = (toolBar) => (
      <AppBar
        style={isArticlePath ? {background: articleCurrentCat ? articleCurrentCat.color : '#F9461E'} : !this.isActive && catIndex != -1 ? 
          {background: this.props.category ? this.props.category.color: '#F9461E'}:
          videoRoutes ? {background: categoryItems && videoIndex != -1 ? categoryItems[videoIndex].color : '#282828' } :
        {background:  '#F9461E'}}
        position="static"
        className={classNames(classes.headerCenter, 'shadowWrapper')}
      >
        <Toolbar
          classes={{
                gutters: classes.toolbarGutters, }}
          className={classes.layout}
        > 
          {toolBar}
        </Toolbar>      
      </AppBar>        
    );

    const logo = "https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/DuwunLogoWhite.svg";

    // const careerLogo = "https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/careers/duwun-careers-logo-white-200x77.png";
    // let logo = isCareersSection ? careerLogo : duwunLogo;

    const isBrandedCategory = categoryItem && categoryItem.is_brand && categoryItem.sponsor != null;
    const isBrandedSubCategory = subCategoryItem && subCategoryItem.is_brand && subCategoryItem.sponsor != null;
    const brandTeaserLogo = isBrandedSubCategory ? subCategoryItem.sponsor.logo : (isBrandedCategory ? categoryItem.sponsor.logo : null);
    const brandSlug = isBrandedSubCategory ? subCategoryItem.sponsor.slug : (isBrandedCategory ? categoryItem.sponsor.slug : null);

    const mobileToolbar = (
      <React.Fragment>
        <span className="menu-button-gtm">
          <IconButton onClick={toggleDrawer(true)} className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton>
        </span>
        <div className={classes.flex} style={catIndex != -1 || videoRoutes ? {display: 'flex', alignItems: 'center', justifyContent: 'center'} : {}}>
          {/* <Link route={isCareersSection ? "/"+careersSlug : "/"}> */}
          <Link route={"/"}>
            <a className="anchor-link">
              <img src={logo} className={classes.logoImgStyle} alt="Duwun logo" />
            </a>
          </Link>
          {/* {(catIndex != -1 && !isCareersSection) || videoRoutes ?
            categoryItems && 
            <Link route={videoRoutes ? "videos": "category"} params={videoRoutes ? {} : { category: categoryItems[catIndex].slug }}>
              <a className="anchor-link" style={{color: '#fff'}}>
                <Typography color="inherit" className={classes.headerCategory}>
              | {
                   
                    videoRoutes ? videoIndex != -1 ? categoryItems[videoIndex].name : 'Videos' : 
                    categoryItems[catIndex].name }
              </Typography>
              </a>
            </Link>
          : null
          } */}
        </div>

        {
          isBrandedCategory || isBrandedSubCategory ?
            <span style={{display: isCareersSection ? 'none' : 'inline-block', opacity: isCareersSection ? 0 : 1, textAlign: 'right', marginRight: 8}} id="branded-logo">
              <Typography color="inherit" variant="caption" style={{ fontSize: 7, marginTop: 12, marginBottom: -2, marginRight: 3 }} >Powered by</Typography>
              <BrandTeaserLogo brandTeaserLogo={brandTeaserLogo} brandSlug={brandSlug} width={80} height={30} imgStyle={{objectFit:'cover'}}/>
            </span>
          : null
        }
        <Link route='searchHome'>
          <a className="anchor-link" style={{display: 'inline-flex', color: '#fff'}}>
            <Icon>search</Icon>
          </a>
        </Link>   
      </React.Fragment>
    )

    const desktopToolbar = (
      <React.Fragment>
        <Typography
          component="div"
          color="inherit"
          align="left"
          noWrap
          className={classes.toolbarTitle}
        >
          {/* <Link route={isCareersSection ? "/"+careersSlug : "/"}> */}
          <Link route={"/"}>
            <a className="anchor-link">
              <img src={logo} className={classes.logoImgStyle} alt="Duwun logo" />
            </a>
          </Link>
          {
            isBrandedCategory || isBrandedSubCategory ?
            <span style={{display: isCareersSection ? 'none' : 'inline-block', opacity: isCareersSection ? 0 : 1, paddingRight: 10}}>
              <Typography color="inherit" style={{ paddingRight: 5, lineHeight: 2, fontSize: 7, marginBottom: -4, marginLeft: 2 }} variant="caption" >Powered by</Typography>
              <BrandTeaserLogo brandTeaserLogo={brandTeaserLogo} brandSlug={brandSlug} width={80} height={30} imgStyle={{objectFit:'cover'}} />
            </span>
            : null
          }
        </Typography>
        
        { 
          ascendingSortCategory && ascendingSortCategory.length > 0 && ascendingSortCategory.map(function (cat, i) {
            const catActive = catIndex == i || (cat.type == 'VIDEO' && videoRoutes) 
            return (
              <div key={i} >
                <Grid item className="customClass">
                  <CustomTab
                    slug={cat.slug}
                    classes={classes}
                    content={cat.name} 
                    isActive={catActive}
                    type={cat.type} 
                    path={cat.path}
                    onActiveTab={this.setActiveTab.bind(this, cat.slug)}
                  />
                </Grid>
              </div> )
            }, this)
        }
        <IconButton style={{color:'#fff'}}>
          <Link route='searchHome'> 
            <a className="anchor-link" style={{display: 'inline-flex', color: '#fff'}}>
              <SearchIcon />
            </a>
          </Link>
        </IconButton>
      </React.Fragment>
    )

    const mobileHeader = () => (
      <div>
        {commonAppBar(mobileToolbar)}
        <SwipeableDrawer
          open={this.state.left}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {sideList}
        </SwipeableDrawer>
      </div>
    );

    const desktopHeaer = () => (
      commonAppBar(desktopToolbar)
    );
    
    return (
      isMobile ? 
      mobileHeader():
      <div>
        <Hidden only={['md', 'lg', 'xl']}>
          {mobileHeader()}
        </Hidden>
        <Hidden smDown>
          {desktopHeaer()}
        </Hidden>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(Header);



