import React, { Component } from "react";
import { render } from "react-dom";

import MailchimpSubscribe from "react-mailchimp-subscribe"
import SubscribeForm from "./subscribeForm";

export default class Mailchimp extends Component {
  render() {
    const url =
    "https://duwun.us14.list-manage.com/subscribe/post?u=e7ec099594126bf6335759ecc&amp;id=416d04a315";
    return (
      <div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <SubscribeForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </div>
    );
  }
}


