import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import ReactPixel from 'react-facebook-pixel';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 0,
        position: 'relative'
    },
    bootstrapRoot: {
        padding: 0,
        width: '100%',
        margin: '0 auto',
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: 'none',
        fontSize: 12,
        padding: '7px 12px',
        width: '98%',
        lineHeight: 1.4,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
       
    },
    btn: {
        boxShadow: 'none',
        fontSize: 12,
        padding: '7px 12px',
        textTransform: 'uppercase',
        width: '98%',
        lineHeight: 1.4,
        minHeight: 0,
        
        
    },
});
class SubscribeForm extends Component{
  componentDidMount(){
    // ************ start facebook pixel ***********

    if (!window.PX_INITIALIZED) {
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };

        ReactPixel.init('548709379041005',options);
        // ReactPixel.init('355678948310337',options);
        window.PX_INITIALIZED = true
    }
    // ReactPixel.pageView(); 					// For tracking page view
    // ReactPixel.track( event, data ) 		// For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
    // ReactPixel.trackCustom( event, data ) 	// For tracking custom events
    // ************ end facebook pixel ***********
  }
  pixelEvent = () => {
    ReactPixel.track('ClickSubscribeBtn', {
        content_name: 'Mail chimp',
        content_category: 'Footer > MailChimp-Subscription',
        content_ids: ['subscribe'],
        content_type: 'SUBSCRIBE-FORM',
        value: 1,
        currency: 'USD'
    }
  )
  return true;
}
    render(){
        const { status, message, onValidated, classes } = this.props;
        let email, name;
        const submit = () =>{
            this.pixelEvent()
            email &&
            name &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
                NAME: name.value
            });
        }
       
      
        return (
            <div>
              {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
              {status === "error" && (
                <div
                  style={{ color: "red" }}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div
                  style={{ color: "green" }}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
               <div ref={node => (name = node)} style={{paddingBottom: 3}}></div>
                    {/* "javascript:void(0)" is no longer supported in react */}
                    {/* <form action="javascript:void(0)" style={{marginBottom: 15}}> */}
                    <div style={{marginBottom: 15}}>
                        <Grid container  direction="row"
                            justify="center"
                            alignItems="center">
                      
                            <Grid item xs={12} sm={6} md={6} style={{marginBottom: 10}} >
                                <div className={classes.bootstrapRoot}>
                                    <input
                                    className={classes.bootstrapInput}
                                    required
                                    ref={node => (email = node)}
                                    type="email"
                                    placeholder="Your email address"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} style={{marginBottom: 10}}>
                                <Button type='submit' onClick={submit} variant="contained" color="primary" className={classNames( classes.btn, 'subscribe-btn-track-gtm')}>
                                    Subscribe
                                </Button>
                            </Grid>
                           
                        </Grid>
                    </div>
                    {/* </form> */}
                    
               
            </div>
          );
    }
}
    SubscribeForm.propTypes = {
        classes: PropTypes.object.isRequired,
    };
  export default withStyles(styles)(SubscribeForm);

