import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

const styles = (theme) => ({
    linkStyle: {
        marginLeft: 5,
    },
    customTooltip: {
        background: '#c1bdbd'
    },
    customTooltipPlacementLeft:{
        [theme.breakpoints.only('xs')]: {
            margin: '30px 24px 0px'
        },
    }
});


class RecaptchaTooltip extends React.Component {
  state = {
    open: false,
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes } = this.props;
    return (
        <ClickAwayListener onClickAway={this.handleTooltipClose}>
            <span>
            <Tooltip
                interactive
                PopperProps={{
                disablePortal: true,
                }}
                onClose={this.handleTooltipClose}
                open={this.state.open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <Typography component="div" style={{ fontSize: 14, padding: '5px 0px' }}>
                        This site is protected by reCAPTCHA and the Google
                        <a className={classes.linkStyle} href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a className={classes.linkStyle} href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </Typography>
                }
                TransitionComponent={Fade}
                placement="right-start"
                classes={{
                    tooltip: classes.customTooltip, 
                    tooltipPlacementLeft: classes.customTooltipPlacementLeft
                  }}
            >
                <IconButton style={{ padding: 6 }} aria-label="Info" onClick={this.handleTooltipOpen} color="inherit" disableRipple>
                    <InfoIcon style={{ fontSize: 18, color: '#C4C4C4' }} />
                </IconButton>
            </Tooltip>
            </span>
        </ClickAwayListener>
          
    );
  }
}

RecaptchaTooltip.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(RecaptchaTooltip);