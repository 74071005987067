import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link} from "routes";
import Mailchimp from '/components/mailchimp';
// import {AdSlot, DFPManager } from 'react-dfp';
import {
    isBrowser,
    isMobile,
    isTablet
  } from "react-device-detect";
import ScrollUpButton from "react-scroll-up-button";
import NewsletterSubscribeForm from '/components/Newsletter/NewsletterSubscribeForm';
import {getFooterTopOffset} from '/components/NextMore/infiniteHandleScroll';
import {c} from '/utils/log';

const styles = theme => ({
    root: {
        flexGrow: 1,
        // position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    paper: {
        padding: 28,
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 0,
        position: 'relative'
    },
   
    textColorBody: {
        fontSize: 11,
        margin:' 10px 0px 20px',
        lineHeight: '18px',
        padding:' 0px 50px',
        textAlign: 'center',
        fontWeight: 'bold',
        // letterSpacing: 1
    },
    textColorTitle:{
        fontSize: 20,
        lineHeight: '25px',
        fontWeight: 'bold',
        color: '#F9461E'
    },
    divider: {
        backgroundColor: '#A5A5A5',
        height: 1,
    },
    flexBasis:{
        flexBasis: 'initial'
    },
    fontIcon: {
        margin: '0px 10px',
        fontSize: 18,
        opacity: .4,
        color: '#fff',
        '&:focus': {
            opacity: 1,
        },
        '&:active': {
            opacity: 1,
        },
    },
    logoImg: {
        width: 240,
        margin: '12px auto 0px',
        opacity: .5,

    },
    img: {
        width: 184,
        height: 46,
        margin: '10px 0px',
        objectFit: 'contain'
    },
    copyright: {
      color: '#C4C4C4',
        fontWeight: 300,
        fontSize: 11,
        padding: '0px 3px'
    },
    footerNav: {
        color: '#A5A5A5',
        fontSize: 11,
        cursor: 'pointer',
        lineHeight: '14px',
        fontWeight: 'bold',
        padding: '0px 20px 14px',
        [theme.breakpoints.only('xs')]: {
          padding: '0px 11px 14px',
        }
    },
    subscribeUsRoutePaper: {
      textAlign: 'center', 
      padding: '2rem 1rem 1rem',
      margin: '2rem'
    },
    logoPrimaryColor: {
      background: 'red',
      width: 124,
      height: 31,
      position: 'relative'
    },
    staticItems: {
      color: '#C4C4C4',
      fontWeight: 300,
      cursor: 'pointer'
    },
    seperate: {
      color: '#C4C4C4',
      fontWeight: 300,
      fontSize: 20, 
      padding: 5
    }
});

class Footer extends Component{
    constructor(props){
        super(props);
        this.state = {
            icons: [
                {id:1, to: 'https://www.facebook.com/duwun', title: 'facebook-f'},
                {id:2, to: 'https://m.me/duwun?ref=Welcome%20message', title: 'facebook-messenger'},
                {id:3, to: 'https://www.instagram.com/duwun/', title: 'instagram'},
                {id:4, to: 'https://www.youtube.com/duwun', title: 'youtube'},
                {id:5, to: 'https://invite.viber.com/?g2=AQA9Au5gBb0XwUj3men7K9GNqPwg6OV615k%2BWT6PtmuBvI1s2rOSiyHTtE6Y0Res', title: 'viber'},
                {id:6, to: 'https://www.linkedin.com/company/duwun', title: 'linkedin-in'},
                //{id:5, to: 'https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&amp;adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&amp;adjust_campaign=Sharebutton&amp;adjust_deeplink=viber%3A%2F%2Fforward%3Ftext%3DCheck%2520this%2520out%253A%2520%2520http%253A%252F%252Fwww.duwun.com.mm%252F', title: 'viber'}
            ],
        }
    }
    subscribeFormSection = (props, isSubscribeUsRoute) => {
      const showNewsLetterForm = false;
      const { classes, categoryItems, uniReadable, routeName } = props; 
      if (showNewsLetterForm)
      return(
        <>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img style={{width: 124, height: 31, marginRight: 7 }} src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/DuwunLogoOrange.svg" alt="Duwun logo" />
            <Typography style={{marginTop: 10, marginLeft: 7}} className={classes.textColorTitle} variant="h6" gutterBottom>
            {
                uniReadable ? "ဒီဂျစ်တယ် သတင်းစာ" :  "ဒီဂ်စ္တယ္ သတင္းစာ"
            }
            </Typography>
            </div>
            <Typography  className={classes.textColorBody} style={isSubscribeUsRoute ? { color: 'rgba(0, 0, 0, 0.87)'} : { color: 'rgba(255, 255, 255, 0.89)'}} variant="body1" gutterBottom>
            {
                uniReadable ?  "သင့် အီးလ်မေး ကနေ နောက်ဆုံးရသတင်းတွေကို ဗုဒ္ဓဟူးနေ့နဲ့သောကြာနေ့တိုင်း အခမဲ့ဖတ်ရှုနိုင်ဖို့ ဒီနေရာမှာ စာရင်းသွင်းလိုက်ပါ။" : 
                    "သင့္ အီးလ္ေမး ကေန ေနာက္ဆုံးရသတင္းေတြကို ဗုဒၶဟူးေန႔နဲ႔ေသာၾကာေန႔တိုင္း အခမဲ့ဖတ္ရႈႏိုင္ဖို႔ ဒီေနရာမွာ စာရင္းသြင္းလိုက္ပါ။"
            }
                
            </Typography>
        </div>
          {
          <NewsletterSubscribeForm isSubscribeUsRoute={isSubscribeUsRoute} routeName={routeName} /> 
          }
        </>
      )
    }
    render(){
        const { classes, categoryItems, uniReadable, routeName } = this.props;  
        console.log('rrr', routeName)
        let articleCategoryItems = categoryItems.filter(item => item.type == 'ARTICLE')  
        let isSubscribeUsRoute = routeName === '/subscribeUs' ? true : false;
        let isSubscribeNotiRoute = routeName === '/subscribeNoti' ? true : false;
        return (
          <>
          {
            (isSubscribeUsRoute || isSubscribeNotiRoute) && 
            <Grid
              container
              justify="center"
              alignItems="center"
            >
                <Grid item xs md={6} sm>
                  <Paper className={classes.subscribeUsRoutePaper}>
                    {isSubscribeNotiRoute ?
                    <div className='onesignal-customlink-container' style={{ padding: 10 }}></div>
                    :
                    this.subscribeFormSection(this.props, isSubscribeUsRoute)
                    }
                  </Paper>
                </Grid>
            </Grid>
          }
          <div className={classes.root} id="footer"> 
            <div className={classes.paper}>
              <Grid
                container
                justify="center"
                alignItems="center"
              >
              
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <Grid item xs md sm>
                    {!isSubscribeUsRoute && !isSubscribeNotiRoute &&
                      <>
                        {this.subscribeFormSection(this.props, isSubscribeUsRoute)}
                        <Divider className={classes.divider} />
                      </>
                    }
                     <div style={{ margin: '23px auto' }}>
                     <div style={{ display: 'flex', justifyContent: 'center' }} >
                        { 
                          categoryItems.map((cat, index) =>(
                            // <Grid item xs md key={index}>
                              <React.Fragment key={index}>
                                <Link route={cat.type == 'VIDEO' ? "videos": cat.type == 'TV' ? "tv" : "category"} params={cat.type == 'VIDEO'|| cat.type == 'TV' ? {} : { category: cat.slug }}>
                                <a className="anchor-link">
                                    <Typography className={classes.footerNav} variant="h6" gutterBottom>
                                        {cat.name}
                                    </Typography>
                                </a>
                                </Link>
                              </React.Fragment>
                            // </Grid>
                          )) 
                        }
                        </div>
                      </div>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                    >
                      {
                            this.state.icons.map((icon, index) =>
                            (<div key={index}>
                              <Grid item xs className={classes.flexBasis}>
                                {/* <Link prefetch href={icon.to}> */}
                                <a href={icon.to}>
                                  <FontAwesomeIcon className={classes.fontIcon} icon={['fab', icon.title]} />
                                </a>
                                {/* </Link> */}
                              </Grid>
                            </div>)
                            )
                        }
                    </Grid>
                    <div className={classes.logoImg}>
                      <Link route="/">
                        <a className="anchor-link">
                         <img className={classes.img} style={{cursor: 'pointer'}} src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/DuwunLogoWhite.svg" alt="Duwun logo" />
                        </a>
                      </Link>
                    </div>
                    <Typography style={{marginBottom: 10}} variant="body1" className={classes.copyright} gutterBottom>
                            Copyright &copy; 2020 Duwun.
                    </Typography>
                    <Typography style={{marginBottom: 10}} variant="body1" className={classes.copyright} gutterBottom>
                      <Link route="privacyPolicy">
                        <a className="footer-anchor-link">
                            <span className={classes.staticItems}>Privacy Policy</span>
                        </a>
                      </Link>
                      <span className={classes.seperate}> | </span>
                      <Link route="aboutUs">
                        <a className="footer-anchor-link">
                            <span className={classes.staticItems}>About Us</span>
                        </a>
                      </Link>
                      <span className={classes.seperate}> | </span>
                      <Link route="aboutUs">
                        <a className="footer-anchor-link">
                            <span className={classes.staticItems}>Jobs</span>
                        </a>
                      </Link>
                      <span className={classes.seperate}> | </span>
                      <Link route="advertiseWithUs">
                        <a className="footer-anchor-link">
                            <span className={classes.staticItems}>Advertise with us</span>
                        </a>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <ScrollUpButton />
            </div>
          </div>
        </>);
    }

    componentDidMount () {
        c.log("footer.js:componentDidMount()");
    }

    componentDidUpdate(previousProps, previousState){
        c.log("footer.js:componentDidUpdate()");
        // DFPManager.refresh();
        // DFPManager.load();
        // DFPManager.setCollapseEmptyDivs( true );
        if(previousProps !== this.props){
            document.getElementById("ctaButton") ? document.getElementById("footer").style.marginBottom = "50px" : document.getElementById("footer").style.marginBottom = "0px"
        }
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
  };


export default withStyles(styles)(Footer);
