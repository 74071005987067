import React, { Component, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Axios from "/plugins/axios";
import Cookies from 'universal-cookie';
// import {
//         GoogleReCaptcha,
//         useGoogleReCaptcha
//       } from 'react-google-recaptcha-v3';
import Typography from '@material-ui/core/Typography';
import RecaptchaTooltip from "./RecaptchaTooltip";

const styles = theme => ({
    bootstrapRoot: {
        padding: 0,
        width: '100%',
        margin: '0 auto',
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 24,
        backgroundColor: theme.palette.common.white,
        fontSize: 12,
        padding: 6,
        width: '98%',
        lineHeight: 1.4,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        textAlign: 'center',
        fontWeight: 'bold',
        // '&:focus': {
        //     border: 'none',
        //     borderColor: '#80bdff',
        //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        // }, 
        '&:focus-visible': {
            outline: 'none'
        }, 
        '&::placeholder': {
           opacity: .7
          }
    },
    subscribeBtn:{
        marginBottom: 10,
        [theme.breakpoints.only('xs')]: {
          marginBottom: 0
        },
    },
    btn: {
        boxShadow: 'none',
        fontSize: 12,
        padding: 6,
        textTransform: 'capitalize',
        width: '98%',
        lineHeight: 1.4,
        minHeight: 0,  
        fontWeight: 'bold',
        letterSpacing: '0.4px',
        borderRadius: 24, 
    },
    protectedByRecaptcha: {
        fontSize: 11,
        padding: 0,
        fontWeight: 300,
        margin: '15px 0px'
    },
});
function Transition(props) {
        return <Slide direction="up" {...props} />;
      }
const NewsletterSubscribeForm = (props) => {
    const { classes, routeName, isSubscribeUsRoute } = props; 
    // const { executeRecaptcha } = useGoogleReCaptcha();
    const [emailValue, setEmailValue] = useState('');
    const [open, setOpen] = useState(false);
    const [errorBox, setErrorBox] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [dynamicAction, setDynamicAction] = useState('homepage');
    const [token, setToken] = useState('');

    useEffect(() => {
        setDynamicAction(routeName)
    }, [])

    const validateEmail = (email) => { 
        // refrence: https://stackoverflow.com/questions/42965541/email-validation-javascript
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    const handleChangeEmail = useCallback(event => {
        setEmailValue(event.target.value);
    }, []);

    const handleClose = () => {
        setOpen(false)
        setErrorBox(false)
    };
    // const handleReCaptchaVerify = useCallback(
    //     token => {
    //       setToken(token);
    //     },
    //     [setToken]
    // );

    const doSubmit = (emailValue, tkn) => {
        // Axios.post(`/contact/chivas-the-venture/send`, {
        //     email: emailValue,
        //     recaptcha_token: token
        // })
        const cookies = new Cookies();
        const stcCookie = cookies.get('sailthru_content', {doNotParse: false});
        console.log('sailthru_content cookie:', stcCookie);
        console.log('recaptcha_token:', tkn);

        axios.post(`/deb`, {
            email: emailValue,
            sailthru_content: stcCookie,
            recaptcha_token: tkn
        })
        .then((response) => {
            if(cookies.get('sailthru_content')) {
                cookies.remove('sailthru_content');
                console.log('sailthru_content removed:', cookies.get('sailthru_content'));                
                // cookies.set('sailthru_content', '', { path: '/' });
                // console.log('sailthru_content:', cookies.get('sailthru_content'), ' is now empty');
                var duwunST = window.duwunST || [];
                if (typeof duwunST.reload === "function") { 
                    console.log('reloading Sailthru.Track...');
                    duwunST.reload();
                }
            }

            if(window.SailthruCustomerId && window.Sailthru) {
                window.Sailthru.integration("userSignUp", { 
                   "email": emailValue,
                   "lists": {}, 
                   "vars": {}, 
                });
                console.log('sailthru_hid cookie will be set upon successful userSignUp Promise');
            }            
            setOpen(true)
            setEmailValue('')
            setInvalid(false)
        })
        .catch((error) => {
            setErrorBox(true)
            setInvalid(false)
        });
    }
      
    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        // if (!executeRecaptcha) {
        //     return;
        // }
        if (!window.grecaptcha) {
            return;
        }        

        if(!validateEmail(emailValue)){
            setInvalid(true)
        }else{
        // const result = await executeRecaptcha('dynamicAction');
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: dynamicAction}).then(function(tkn) {
                // Add your logic to submit to your backend server here.
                // setToken(tkn);
                doSubmit(emailValue, tkn);                
            });
        });
    
    }}, [emailValue]);
    
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if(!validateEmail(emailValue)){
    //         setInvalid(true)
    //     }else{

    //     const cookies = new Cookies();
    //     const stcCookie = cookies.get('sailthru_content', {doNotParse: false});
    //     console.log('sailthru_content cookie:', stcCookie);

    //     axios.post(`/deb`, {
    //         email: emailValue,
    //         sailthru_content: stcCookie,
    //         recaptcha_token: token
    //     })
    //     .then((response) => {

    //         if(cookies.get('sailthru_content')) {
    //             cookies.remove('sailthru_content');
    //             console.log('sailthru_content removed:', cookies.get('sailthru_content'));                
    //             // cookies.set('sailthru_content', '', { path: '/' });
    //             // console.log('sailthru_content:', cookies.get('sailthru_content'), ' is now empty');
    //             var duwunST = window.duwunST || [];
    //             if (typeof duwunST.reload === "function") { 
    //                 console.log('reloading Sailthru.Track...');
    //                 duwunST.reload();
    //             }
    //         }

    //         if(window.SailthruCustomerId && window.Sailthru) {
    //             window.Sailthru.integration("userSignUp", { 
    //                "email": emailValue,
    //                "lists": {}, 
    //                "vars": {}, 
    //             });
    //             console.log('sailthru_hid cookie will be set upon successful userSignUp Promise');
    //         }
    //         setOpen(true)
    //         setEmailValue('')
    //         setInvalid(false)           
    //     })
    //     .catch((error) => {
    //         setErrorBox(true)
    //         setInvalid(false)
    //     });
    // }
    // }
   
    return (
        <div style={{marginBottom: 15, padding: '0px 21px'}}>
        {
            invalid ? <span style={{ color: 'red' }}>Please enter a valid email address.</span> : null
        }
            <form className={classes.container} autoComplete="off" onSubmit={handleSubmit}>
            <div>
                    <Grid container  direction="row"
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} sm={6} md={6} style={{marginBottom: 10}} >
                            <span style={{color: '#fff'}}>{invalid}</span>
                            <div className={classes.bootstrapRoot}>
                                <input
                                className={classes.bootstrapInput}
                                required
                                type="email"
                                placeholder="Your email address"
                                value={emailValue}
                                onChange={handleChangeEmail}   
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className={classes.subscribeBtn}>
                            <Button type='submit' variant="contained" color="primary" className={classNames( classes.btn, 'subscribe-btn-track-gtm')}>
                                Subscribe
                            </Button>
                        </Grid>
                        
                        {/* <GoogleReCaptcha
                            action={dynamicAction}
                            onVerify={handleReCaptchaVerify}
                        /> */}
                    </Grid>
                </div>
            </form>   
            <Typography component="div" className={classes.protectedByRecaptcha} style={isSubscribeUsRoute ? {color: "#000"} :{ color: '#A5A5A5'}}>
                <span>Protected by reCAPTCHA</span>
                <RecaptchaTooltip />
            </Typography> 
            <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
                Thank you for subscription.
            </DialogTitle>
        </Dialog>
        <Dialog
            open={errorBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
                Please try again.
            </DialogTitle>
            
        </Dialog>
        </div>
        );
 
}

  export default withStyles(styles)(NewsletterSubscribeForm);


