
export const getFooterTopOffset = () => {
    let footer = document.getElementById("footer");
    if(footer) {
        let offset = footer.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);
        return offset;
    }
    return 0;
}

export const infiniteHandleScroll = (that) => {
    
    if(that.state.next != null && that.nextPageNumber < 6 && 
        (!that.initialPageLoaded || that.previousFooterTopOffset != that.newFooterTopOffset )) {    
        let footer = document.getElementById("footer");
        // console.log(footer.getBoundingClientRect().top, " : ", that.previousFooterTopOffset, " vs ", that.newFooterTopOffset);
        if(footer && footer.getBoundingClientRect().top < screen.height + 300) {
            if(!that.state.loading) {
                // console.log("that: ", that);
                // console.log("event: ", event);  
                console.log(footer.getBoundingClientRect().top, " < ", screen.height + 300);
                that.previousFooterTopOffset = getFooterTopOffset();
                // console.log("infiniteHandleScroll:previousFooterTopOffset", that.previousFooterTopOffset);
                console.log('loading next page...', that.state.next);
                that.nextPage(that.state.next);
                that.initialPageLoaded = true;
            }
        }
    }
}
